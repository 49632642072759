<template>
    <div>
        <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
            <md-content>
                <div class="dialog-header">
                    <div class="dialog-title">Danh sách nhân viên</div>
                    <div class="dialog-close" @click="showDialog = false">
                        Close
                    </div>
                </div>
                <div class="dialog-search">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <md-input v-model="search.code" placeholder="Tìm theo mã"></md-input>
                                <md-icon>search</md-icon>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <md-input v-model="search.name" placeholder="Tìm theo tên"></md-input>
                                <md-icon>search</md-icon>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="deptName" @md-selected="getDeptSelected" :md-options="depts" @md-changed="getDepts"  @md-opened="getDepts" md-input-placeholder="Tìm theo phòng ban">
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.name }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openDept()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <table class="data-table">
                                <thead>
                                    <tr>
                                        <th style="width: 50px;">#</th>
                                        <th style="width: 350px;">Mã nhân viên</th>
                                        <th style="width: 400px;">Tên nhân viên</th>
                                    </tr>
                                </thead>
                                <tbody v-if="loadding == true" style="height: 150px;">
                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                </tbody>
                                <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                    <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                                </tbody> 
                                <tbody v-if="loadding == false && pager.totalItems > 0">
                                    <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                        <td class="center">{{index + 1}}</td>
                                        <td><a @click="selected(item)">{{item.code}}</a></td>
                                        <td>{{item.fullName}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="dialog-footer" v-if="pager.totalPages > 0">
                    <div class="dialog-paging">
                        <div v-if="pager.totalPages > 0">
                            <v-pagination v-model="search.pageIndex"
                            :page-count="pager.totalPages"
                            @change="onChange"></v-pagination>
                        </div>
                    </div>
                    <div>|</div>
                    <div v-if="pager.totalPages > 0" class="dialog-data-info">Xem <span>{{pager.startIndex}}-{{pager.endIndex}}/{{pager.totalItems}}</span> bản ghi</div>
                    <div>|</div>
                    <div class="dialog-paging-row-of-page">Rows/page: <a v-for="item in rowPerPageOptions" :key="item.id" :class="{'current-row-of-page': search.pageSize == item.id}" @click="changeRowPerPage(item.id)">{{item.text}}</a></div>
                    <div>|</div>
                    <div class="dialog-refresh"><a @click="refresh()">Làm tươi</a></div>
                </div>
            </md-content>
        </md-dialog>
        <deptList ref="deptList" @close="closeDept"/>
    </div>
</template>

<script>
    import staffService from '../../api/staffService';
    import departmentService from '../../api/departmentService'; 
    import messageBox from '../../utils/messageBox'; 
    import common from '../../mixins';
    import vPagination from '../Pagination.vue';
    import deptList from './_DepartmentList.vue';

    export default {
        components: {
            vPagination,
            deptList
        },
        data: () => ({
            showDialog: false,
            ids: [],
            data: [],
            pager: { totalPages: 1 }, 
            loadding: false, 
            search: { pageIndex: 1, pageSize: common.pageSize, deptId: 0, code: '', name: '' },
            rowPerPageOptions: common.rowPerPagePopupOptions,
            depts: [],
            deptName: ''
        }),

        created() {
            
        },

        methods: { 
            closeDept(item){
                this.deptName = item.name;
                this.search.deptId = item.id;
                this.$refs.deptList.close();
            },

            getDeptSelected(val){
                this.search.deptId = val.id;
                this.deptName = val.name;
            },

            openDept(){
                this.$refs.deptList.open();
            },

            getDepts(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, keyword:  searchTerm };
                departmentService.getDepartments(search).then((response) => {
                    if(response.statusCode == 200){
                        this.depts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            selected(item){
                this.$emit('close', item);
            },

            onChange: function () {
                this.getData();
            },

            open(deptId){
                if(deptId != null && deptId > 0){
                    this.search.deptId = deptId;
                }
                this.getData();
                this.showDialog = true;
            },

            close(){
                this.showDialog = false;
            },

            changeRowPerPage(pageSize){
                this.search = { pageIndex: 1, pageSize: pageSize, deptId: 0, code: '', name: '' };
                this.getData();
            },

            refresh() {
                this.search = { pageIndex: 1, pageSize: common.pageSize, deptId: 0, code: '', name: '' };
                this.getData();
            },

            getData(){
                this.loadding = true;
                staffService.getStaffs(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items; 
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; }); 
            },
        },
        watch: { 
            deptName: function (val) { 
                if(val == ''){
                    this.search.deptId = 0;
                }
            },
            'search.pageIndex': function () { 
                this.getData(); 
            }, 
            'search.pageSize': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            },
            'search.code': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            },
            'search.name': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            },
            'search.deptId': function () { 
                this.search.pageIndex = 1; 
                this.getData(); 
            } 
        }, 
    }
</script>

<style lang="css" scoped>
    .md-content {
        padding: 10px;
        width: 800px;
        height: 455px;
        max-width: 800px;
        max-height: 455px;
    }
    .dialog-content {
        height: 345px;
    }
    .dialog-content table{
        height: 340px;
        min-height: 340px !important;
    }
</style>